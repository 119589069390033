import { MissingImage } from 'customizations'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Modal, withErrorBoundary, GoogleStaticMap, Formatter, NoResults } from 'shared'
import { Model, ParkApi } from 'api';
import { SmartButton, SmartButtonLink } from 'ui/smart-buttons'
import { EventCardMini } from 'ui/events'
import ParkReservationBlock from './ParkReservationBlock';
import ParkReservationSlots from './ParkReservationSlots'

class ParkModal extends Component {
  state = { timeSlots: null }

  componentDidMount() {
    // if (application.canNestChat) App.state.setObject({ base: application.path })
    this.loadData({})
  }

  async loadData(prevProps) {
    let currDate = moment(application.todayDateString)
    this.setState({ date: currDate, timeSlots: null })
    await ParkApi.loadTimeSlotsForDate(this.props.park, currDate, null).then(data =>
        this.setState({ timeSlots: data }))
  }

  render() {
    let { park, smartButtons, reloadSmartButtons, base = park.baseCollectionName, subroute } = this.props
    let [ date, time ] = Model.extractSubrouteArgs(subroute)
    let { timeSlots } = this.state
    smartButtons = smartButtons && smartButtons.filter(b => !(b.kind == 'place-reservation' && b.place_id == park.id && !b.id))

    return (
      <div className="ParkModal custom-modal">
        <div className="modal-head">
          <img className="modal-image" src={park.image_url || MissingImage} />
          <img className="modal-image-preview" src={park.image_preview_url || MissingImage} />
          <div className="modal-titlebar">
            <h3 className="modal-title">{park.name}</h3>
            <div className="modal-titlebar-actions"></div>
          </div>
          <Modal.CloseButton />
        </div>
        <div className="modal-body">
          <div className="left-pane">
            <div className="entity-map">
              { park.isFeatureEnabled('map') &&
                <GoogleStaticMap defaultZoom={15} defaultCenter={park.coords} address={park.address} />
              }
            </div>
            <div className="left-pane-text-content">
              <div className="entity-address">
                { park.isFeatureEnabled('map') &&
                  <a href={park.address_map_url} className="text-ligher" target="_blank">{park.address}</a>
                }
              </div>
              { park.isPark && <hr /> }
              { park.isPark &&
                <div className="entity-schedule">
                  <div className="es-state">
                    { park.isFeatureEnabled('opening') && park.opening_status_str }
                  </div>
                  <ul className="es-list">
                    {park.isFeatureEnabled('schedule') && park.open_periods && Object.keys(park.open_periods).map(period =>
                      <li key={period}>
                        <span className="es-weekday">{period}</span>
                        <span className="es-hours">{park.opening_hours_for(period)}</span>
                      </li>
                    )}
                  </ul>
                </div>
              }
              {/*{park.is_reservable &&
                <div className="entity-today-slots">
                  <div className="es-state">
                    Open Availability Today
                  </div>
                  <div className="es-list">
                    {
                      park.hasServices ?
                         <NoResults className = 'no-result compact' > No Availability Today < /NoResults>
                        :
                          timeSlots ?
                            timeSlots.length ?
                              timeSlots.map(ts => this.renderItem(ts))
                              :
                              <NoResults className='no-result compact'>No Availability Today </NoResults>
                            : ''
                    }
                  </div>
                </div>
              }*/}
            </div>
          </div>
          <div className="right-pane">
            <div className="entity-description">
              <p dangerouslySetInnerHTML={{__html: Formatter.simpleFormat(park.description)}}></p>
            </div>
            {
              smartButtons && (!park.isRetail || (park.isRetail && park.isJoinRequired)) && time == null &&
                <div className="entity-smart-buttons smart-buttons">
                  {smartButtons.map(smartButton =>
                    <SmartButton {...{smartButton, reloadSmartButtons, base}} object={park} key={smartButton.key} returnPath={application.path} />
                  )}
                </div>
            }

            {
              User.current.guest &&
                <>
                  {!User.current.regular && park.isRetail && park.isJoinRequired &&

                    <div class="entity-smart-buttons smart-buttons">
                      <SmartButtonLink text="Follow" className="smart-button btn" to={`/marketplace-signup/?retail_id=${park.id}`} />
                    </div>
                  }
                </>
            }
            {
              (park.embedded_url || park.embedded_html) &&
                <iframe id="park-embedded-page" className="park-iframe" src={park.embedded_url} srcDoc={park.embedded_html}></iframe>
            }
            {
              !User.current.isGuestExperience &&
                <>
                  {park.reservations && time == null &&
                    <ParkReservationsListing reservations={park.reservations} />
                  }
                  {park.isPark && park.is_reservable &&
                    <ParkReservationBlock park={park} reloadSmartButtons={reloadSmartButtons} base={base} subroute={subroute} />
                  }
                  {
                    time == null &&
                      <div className="entity-children">
                        {park.events.map(event =>
                          <EventCardMini event={event} base={`${base}/${park.uid}`} key={event.uid} />
                        )}
                      </div>
                  }
                </>
            }
          </div>
        </div>
      </div>
    )
  }

  renderItem(slot) {
    let { time, reservation, available, militaryTime } = slot
    let options = { }
    options = {
      text: `${ reservation ? `Reserved: ` : `Open:` } ${slot.desc}`,
      className: classNames('mb-2 btn-sm park-reservation-btn', { gray: reservation ? true : false }),
      key: time,
    }

    if (User.current && User.current.guest) {
      options.click = () => {
        App.state.setObjectSilent({ afterSignupPath: App.path })
        App.go(`${this.props.base}/guest-signup`)
      }
    }
      let url = ``
      return <SmartButtonLink to={url} {...options} click={null} />
  }
}

const ParkReservationsListing = ({reservations}) =>
  reservations.map(reservation =>
    <div key={reservation.key}
      className={classNames('park-reservation-request-status', { 'approved': reservation.isApproved, 'pending': reservation.isPending })}
    >
      {Formatter.dateUS(reservation.date)} {Formatter.hhmmToAmpm(reservation.time)}–{Formatter.hhmmToAmpm(reservation.end_time)}{' '}
      {reservation.isPending ? 'Reservation submitted' : 'Reserved'}
      {reservation.service && <span>{': '}{reservation.service.name}</span>}
    </div>
  )

export default SmartButton.containerComponent('park', withErrorBoundary(ParkModal))
